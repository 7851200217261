@import "./variables";
$borderColor: #000;
$shadows-small: multiple-box-shadow(700);
.Container {
  @include Mywidth(100%);
  display: flex;
  background: url("../../images/bg.jpg");
  background-size: cover;
  .headerContainer {
    display: $display;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    .links {
      display: $display;
      justify-content: center;
      align-items: center;
      height: 50px;
      margin-top: 2rem;
      @include Mywidth(63%);
      background-image: linear-gradient(160deg, #747b94 0%, #2d3c53 60%);
    }
    .infoContainer {
      display: $display;
      align-items: top;
      border: 1px solid $borderColor;
      border-radius: 15px;
      margin-top: 10px;
      max-width: 60%;

      background-image: linear-gradient(160deg, #898fa5 0%, #eef0f3 74%);

      h3,
      p {
        text-align: left;
      }
      .image {
        max-width: 100%;
        height: auto;
        border-right: 1px solid $borderColor;
        margin-right: 10px;
        transition: transform 0.5s ease;
      }
      .image:hover {
        transform: scale(2);
        border: 0;
      }
      .intro {
        line-height: 0.3;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .Container {
    .headerContainer {
      .infoContainer {
        align-items: center;
        flex-direction: column;
        border: none;
        padding: 20px;
        width: 100%;
        h3,
        p {
          text-align: left;
        }
        .image {
          max-width: 100%;
          border-right: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .Container {
    .headerContainer {
      padding: 5px;
      .infoContainer {
        padding: 10px;
        max-width: 100%;
        .image {
          float: left;
          max-width: 40%;
          max-height: 300px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .Container {
    .headerContainer {
      padding: 10px;
      .infoContainer {
        padding: 20px;
        width: 100%;
        .textContainer {
          padding-right: 30px;
        }
        .image {
          float: left;
          max-width: 40%;
          max-height: 300px;
        }
      }
    }
  }
}
