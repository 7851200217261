.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 80vh;
  margin-top: 50px;
  .image {
    width: 70%;
    height: auto;
    -webkit-box-shadow: 0px 5px 10px 0px #000000;
    box-shadow: 0px 5px 10px 0px #000000;
    &:hover + .hovercap {
      visibility: visible;
      opacity: 1;
    }
  }
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // .images {
    //   object-fit: contain;
    //   width: 70%;
    //   height: 600px;
    //   -webkit-box-shadow: 0px 5px 10px 0px #000000;
    //   box-shadow: 0px 5px 10px 0px #000000;
    //   &:hover + .hovercap {
    //     visibility: visible;
    //     opacity: 1;
    //   }
    // }
    .hovercap {
      box-sizing: border-box;
      width: 70%;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      visibility: none;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
}
