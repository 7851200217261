.container{
    background: url('../../images/dashboardBg.jpg');
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    div{
        display: flex;
        justify-content: center;
        // align-items: center;
        // height: 100vh;
    }
}