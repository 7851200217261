.navbutton:hover {
  background-color: rgb(0, 0, 0);
}
.nav {
  -webkit-box-shadow: 0px 5px 10px 0px #000000;
  box-shadow: 0px 5px 10px 0px #000000;
  background-color: #2e3b55;
  position: sticky;
}
html {
  scroll-behavior: smooth;
}
.icon{
  width: 32px;
  height: 32px;
}

.icon2{
  width: 32px;
  height: 32px;
}
.button{
 color: white;
 background:red;
}

@media only screen and (max-width: 899px) {
 .icon{
  visibility: hidden;
 }
}
@media only screen and (min-width: 900px) {
  .icon2{
   visibility: hidden;
  }
 }

