.container {
  .section {
    background-color: #2e3b55;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 5px 10px 0px #000000;
    box-shadow: 0px 5px 10px 0px #000000;
  }
  .row {
    display: flex;
    .column1 {
      flex: 50%;
      padding: 15px;

      text-align: right;
      border-right: 1px solid black;
    }
    .column2 {
      flex: 50%;
      padding: 15px;

      text-align: left;
    }
  }
}
