.social {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}

.social a {
  text-decoration: none;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  font-size: 1.5rem;
  color: #eee;
  cursor: pointer;
}

.social a:hover {
  transform: scale(1.5);
  transition: transform 0.5s ease;
}

.social a span {
  font-size: 1rem;
}

.social a:hover::before,
.social a:hover::after {
  display: block;
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background: #fda8cf;
  border-radius: 10px;
  z-index: -1;
  animation: 1s clockwise infinite;
}

.social a:hover:after {
  background: #f3ce5e;
  animation: 1s counterclockwise infinite;
}

@keyframes clockwise {
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;
  }
}

@keyframes counterclockwise {
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;
  }
}
